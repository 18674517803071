@import '../../globalColor';
.profile-pic {
  border-radius: 25%;
    border: 0.2rem solid $buttonColor;
    // margin-left: auto;
    width: 100%;
    max-width: 400px;
    height: auto;
    box-shadow: 0 30px 30px -30px rgba(0,0,0,.1);
    transition: all .3s ease-out;
}


@media (max-width:768px) {
  .profile-pic{
    max-width: 250px !important;
  }
}